<template>
  <div>
    <!-- H1 tag for SEO - contact TuanAnh -->
    <h1 style="position:fixed; color: transparent;">{{BRAND_NAME}} - {{SOLOGAN}}</h1>
    <Default v-if="isDesktop" class="page-search">
      <SearchFilter @search="onCLickSearch" @reset="onCLickReset"/>

    <!--    <div class="share-report-button" v-if="isClickedSearched">-->

    <!--      <a-button @click="onShareReport" type="default" size="large" :icon="isLoadingShareCustomQuery ? 'loading' : 'share-alt'">-->
    <!--        Chia sẻ báo cáo-->
    <!--      </a-button>-->
    <!--    </div>-->
      <div style="text-align: center; margin-top: 16px;" v-if="permissionAvailableName">
        <template v-if="(waitingTransaction && waitingTransaction.length > 0) || (updatedTransaction && updatedTransaction.length > 0)">
          <p v-if="waitingTransaction && waitingTransaction.length === 1">Bạn đang có {{ waitingTransaction.length }} lệch chờ xác nhận thanh toán, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail(waitingTransaction[0].voucher_id, waitingTransaction[0].voucher_code)">Xem chi tiết</span></p>
          <p v-if="waitingTransaction && waitingTransaction.length > 1">Bạn đang có {{ waitingTransaction.length }} lệch chờ xác nhận thanh toán, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail">Xem chi tiết</span></p>
          <template v-if="updatedTransaction && updatedTransaction.length === 1">
            <p v-if="updatedTransaction[0].isComplete">Bạn đã thanh toán thành công và được áp dụng gói cước, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail(updatedTransaction[0].voucher_id, updatedTransaction[0].voucher_code)">Xem chi tiết</span></p>
            <p v-else>Có lỗi xảy ra trong việc thanh toán gói cước của bạn, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail(updatedTransaction[0].voucher_id, updatedTransaction[0].voucher_code)">Xem chi tiết</span></p>
          </template>
          <template v-else-if="updatedTransaction && updatedTransaction.length > 1">
            <p>{{updatedTransaction.length}} Thanh toán của bạn đã được cập nhật trạng thái, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail">Xem chi tiết</span></p>
          </template>
        </template>
      </div>
      <template v-if="permissionAvailableName && !showPopupSystemError && isLogin">
    <!--      <div v-if="!this.isDefaultAccountPlan && isExpired" style="text-align: center; margin-top: 16px;">-->
    <!--        <p style="font-size: 20px;"><strong>{{accountPlanName}}</strong> của bạn <span class="text-highlight" style="color: #ee0033">đã hết hạn</span></p>-->
    <!--&lt;!&ndash;        <p style="font-size: 20px;">&ndash;&gt;-->
    <!--&lt;!&ndash;          <strong>{{accountName}}</strong> của bạn còn <span class="text-highlight" v-bind:style="{color: getSearchCountdownWarningColor(freeSearchCount)}">{{freeSearchCount}} lượt phân tích</span> trong hôm nay&ndash;&gt;-->
    <!--&lt;!&ndash;          <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>&ndash;&gt;-->
    <!--&lt;!&ndash;        </p>&ndash;&gt;-->
    <!--        <p style="margin-top: 8px; font-size: 14px">-->
    <!--          <span @click="onClickShowPricing" class="text-highlight" style="cursor: pointer;">Nâng cấp Tài khoản</span>-->
    <!--&lt;!&ndash;          <span> để có thêm nhiều lượt phân tích và tính năng hữu ích</span>&ndash;&gt;-->
    <!--          <span> hoặc nâng cấp thông qua <a target="_blank" style="color: #ff7227; cursor: pointer" @click="onClickBtnPromote">gói giới thiệu</a></span>-->
    <!--        </p>-->
    <!--      </div>-->
        <div v-if="typeof freeSearchCount === 'number' && freeSearchCount <= 10" style="text-align: center; margin-top: 16px;">
          <p style="font-size: 20px;">
            <strong>{{accountName}}</strong> của bạn còn <span class="text-highlight" v-bind:style="{color: getSearchCountdownWarningColor(freeSearchCount)}">{{freeSearchCount}} lượt phân tích</span> trong hôm nay
            <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
          </p>
          <p style="margin-top: 8px; font-size: 14px">
            <span>Để không bị giới hạn này, hãy <span @click="onClickShowPricing" class="text-highlight" style="cursor: pointer;">Nâng cấp Tài khoản</span></span>
          </p>
        </div>
      </template>
      <template v-if="!showPopupSystemError && !isLogin">
        <div style="text-align: center; margin-top: 16px;">
          <p style="font-size: 20px;">
            Vui lòng <span class="text-highlight cursor-pointer" @click="onClickLogin">đăng nhập</span> để <strong>Phân tích miễn phí</strong>
          </p>
        </div>
      </template>
      <a-spin v-if="responseStatus && permissionAvailableName" :spinning="isClickSearch" class="report-content container-bg-white container-space-default border-solid" id="report-wrap">
        <div class="duration-range-comparison-wrap" v-if="!isClickSearch && durationComparisonArray && durationComparisonArray.length">
          <div class="duration-range-comparison" v-if="isBusinessAccount || isHigherPriorityAccount(PERMISSION.market_standard.priority)">
            <span>So sánh với</span>
            <div class="duration-range-comparison-option-container">
              <div v-for="(item, index) in durationComparisonArray"
                   :key="index"
                   class="duration-range-comparison-option"
                   :class="{'active': durationCompareIndex === index, 'disabled': !isBusinessAccount && !isHigherPriorityAccount(PERMISSION.market_standard.priority)}"
                   @click="onClickComparison(index)"
              >
                {{item.label}}
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <h2 class="page-title">Báo cáo Phân tích thị trường</h2>
          <SaveReportComponent v-if="!isClickSearch && !isMultiplePlatform" :is-saved-default="isSavedReport" class="absolute top-0 right-0 mr-4"/>
        </div>

        <h2 class="page-sub-title" v-if="!isClickSearch && durationRangeOptionArray && durationRangeOptionArray[0] && durationRangeOptionArray[1]">
          <template v-if="!durationRangeOptionArray[0].isSame(durationRangeOptionArray[1])">
            Từ {{ durationRangeOptionArray[0].format('DD-MM-YYYY') }} đến
            {{ durationRangeOptionArray[1].format('DD-MM-YYYY') }}
          </template>
          <template v-else>
            Cho ngày {{ durationRangeOptionArray[0].format('DD-MM-YYYY') }}
          </template>
        </h2>
        <h2 class="page-sub-title" v-if="!isClickSearch && isBusinessAccount && typeof durationCompareIndex === 'number' && durationComparisonArray && durationComparisonArray.length">
          <template v-if="!durationComparisonArray[durationCompareIndex].value[0].isSame(durationComparisonArray[durationCompareIndex].value[1])">
            So sánh với {{durationComparisonArray[durationCompareIndex].value[0].format('DD-MM-YYYY')}} đến {{durationComparisonArray[durationCompareIndex].value[1].format('DD-MM-YYYY')}}
          </template>
          <template v-else>
            So sánh với ngày {{durationComparisonArray[durationCompareIndex].value[0].format('DD-MM-YYYY')}}
          </template>
        </h2>
        <div class="container-space-default border-solid" v-if="!isClickSearch">
          <StatisticGeneral @mounted="onMountedStatisticGeneral"/>
        </div>
        <div v-if="!isClickSearch">
          <div class="container-space-default border-solid">

            <MarketChartV2 :is-comparison-mode="typeof durationCompareIndex === 'number'" :is-search-demo-init="isSearchDemoInit"/>

            <div class="relative" id="product-section">
              <a-tabs v-model="activeTab" class="margin-top-25">
                <a-tab-pane key="table-product">
                  <h2 class="title" slot="tab">
                    <span class="border-left-line"></span>
                    Sản phẩm bán chạy
                  </h2>
                  <div class="flex flex-row justify-space-between mb-6">
                    <div class="flex items-center">
                      <a-select v-model="iLimit" style="width: 200px" class="margin-right-20" :default-value="12" @change="onChangeLimitNumberProduct">
                        <a-select-option :value="12">Hiển thị 12 sản phẩm</a-select-option>
                        <a-select-option :value="24">Hiển thị 24 sản phẩm</a-select-option>
                        <a-select-option :value="48">Hiển thị 48 sản phẩm</a-select-option>
                        <a-select-option :value="96">Hiển thị 96 sản phẩm</a-select-option>
                      </a-select>
                      <a-tooltip>
                        <template slot="title">
                          Hiển thị dạng bảng
                        </template>
                        <div @click="productViewType = 'table'"
                             class="mr-2 p-1 cursor-pointer rounded"
                             :class="productViewType === 'table' ? 'text-white bg-primary-500' : 'text-gray-400 hover:bg-primary-custom'"
                             style="height: 32px"
                        >
                          <IconList width="24" height="24"/>
                        </div>
                      </a-tooltip>
                      <a-tooltip v-if="productsResult && productsResult.length">
                        <template slot="title">
                          Hiển thị dạng ô
                        </template>
                        <div class="cursor-pointer p-1 rounded"
                             :class="productViewType === 'grid' ? 'text-white bg-primary-500' : 'text-gray-400 hover:bg-primary-custom'"
                             style="height: 32px"
                             @click="productViewType = 'grid'"
                        >
                          <Icon4Square width="24" height="24"/>
                        </div>
                      </a-tooltip>
                    </div>
                    <div class="flex items-center">
                      <a-popover v-if="productViewType === 'grid'" placement="bottom" trigger="click" title="Sắp xếp">
                        <a-button class="relative flex justify-center items-center"
                                  style="color: #2368D4; background-color: rgba(0, 122, 255, 0.1) !important; border: none;"
                                  size="large"
                        >
                          <IconSort style="color: #2368D4"/>
                          Sắp xếp
                        </a-button>
                        <div slot="content" class="">
                          <div>
                            <a-select style="width: 200px" class="margin-right-20" v-model="productSortValue" v-if="allProductSort && allProductSort.length" @change="onChangeProductSort">
                              <a-select-option v-for="searchOption in allProductSort" :key="`sort_by_${searchOption.value}`" :value="searchOption.value">{{ searchOption.label }}</a-select-option>
                            </a-select>
                            <a-select style="width: 120px" class="margin-right-20" v-model="productSortDirection" @change="onChangeProductSort">
                              <a-select-option value="asc">
                                <div class="flex items-center">
                                  {{productSortValue === 'product_name' ? 'A' : 'Thấp'}}
                                  <svg class="mx-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.875195 6.99945C0.875195 7.11548 0.921288 7.22676 1.00333 7.30881C1.08538 7.39085 1.19666 7.43695 1.31269 7.43695L11.6316 7.43695L8.87794 10.1897C8.83727 10.2304 8.805 10.2787 8.78299 10.3318C8.76097 10.385 8.74964 10.4419 8.74964 10.4994C8.74964 10.557 8.76097 10.6139 8.78299 10.6671C8.805 10.7202 8.83727 10.7685 8.87794 10.8092C8.91862 10.8499 8.96691 10.8821 9.02006 10.9042C9.07321 10.9262 9.13017 10.9375 9.18769 10.9375C9.24522 10.9375 9.30218 10.9262 9.35533 10.9042C9.40848 10.8821 9.45677 10.8499 9.49744 10.8092L12.9974 7.3092C13.0382 7.26856 13.0705 7.22028 13.0926 7.16713C13.1146 7.11398 13.126 7.05699 13.126 6.99945C13.126 6.9419 13.1146 6.88492 13.0926 6.83177C13.0705 6.77862 13.0382 6.73034 12.9974 6.6897L9.49744 3.1897C9.45677 3.14902 9.40848 3.11676 9.35533 3.09474C9.30218 3.07273 9.24522 3.0614 9.18769 3.0614C9.13017 3.0614 9.07321 3.07273 9.02006 3.09474C8.96691 3.11676 8.91862 3.14902 8.87794 3.1897C8.83727 3.23038 8.805 3.27867 8.78299 3.33181C8.76097 3.38496 8.74964 3.44192 8.74964 3.49945C8.74964 3.55697 8.76097 3.61394 8.78299 3.66708C8.805 3.72023 8.83727 3.76852 8.87794 3.8092L11.6316 6.56195L1.31269 6.56195C1.19666 6.56195 1.08538 6.60804 1.00333 6.69009C0.921288 6.77214 0.875195 6.88342 0.875195 6.99945Z" fill="#898989"/>
                                  </svg>
                                  {{productSortValue === 'product_name' ? 'Z' : 'Cao'}}
                                </div>
                              </a-select-option>
                              <a-select-option value="desc">
                                <div class="flex items-center">
                                  {{productSortValue === 'product_name' ? 'Z' : 'Cao'}}
                                  <svg class="mx-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.875195 6.99945C0.875195 7.11548 0.921288 7.22676 1.00333 7.30881C1.08538 7.39085 1.19666 7.43695 1.31269 7.43695L11.6316 7.43695L8.87794 10.1897C8.83727 10.2304 8.805 10.2787 8.78299 10.3318C8.76097 10.385 8.74964 10.4419 8.74964 10.4994C8.74964 10.557 8.76097 10.6139 8.78299 10.6671C8.805 10.7202 8.83727 10.7685 8.87794 10.8092C8.91862 10.8499 8.96691 10.8821 9.02006 10.9042C9.07321 10.9262 9.13017 10.9375 9.18769 10.9375C9.24522 10.9375 9.30218 10.9262 9.35533 10.9042C9.40848 10.8821 9.45677 10.8499 9.49744 10.8092L12.9974 7.3092C13.0382 7.26856 13.0705 7.22028 13.0926 7.16713C13.1146 7.11398 13.126 7.05699 13.126 6.99945C13.126 6.9419 13.1146 6.88492 13.0926 6.83177C13.0705 6.77862 13.0382 6.73034 12.9974 6.6897L9.49744 3.1897C9.45677 3.14902 9.40848 3.11676 9.35533 3.09474C9.30218 3.07273 9.24522 3.0614 9.18769 3.0614C9.13017 3.0614 9.07321 3.07273 9.02006 3.09474C8.96691 3.11676 8.91862 3.14902 8.87794 3.1897C8.83727 3.23038 8.805 3.27867 8.78299 3.33181C8.76097 3.38496 8.74964 3.44192 8.74964 3.49945C8.74964 3.55697 8.76097 3.61394 8.78299 3.66708C8.805 3.72023 8.83727 3.76852 8.87794 3.8092L11.6316 6.56195L1.31269 6.56195C1.19666 6.56195 1.08538 6.60804 1.00333 6.69009C0.921288 6.77214 0.875195 6.88342 0.875195 6.99945Z" fill="#898989"/>
                                  </svg>
                                  {{productSortValue === 'product_name' ? 'A' : 'Thấp'}}
                                </div>
                              </a-select-option>
                            </a-select>
                          </div>
                          <p class="mt-4"><span class="cursor-pointer" style="color: #2368D4;" @click="resetProductSort">Reset</span></p>
                        </div>
                      </a-popover>
                      <a-tooltip v-if="activeTab === 'table-product'" placement="bottom" class="margin-left-10">
                        <!--              <template slot="title" v-if="remainProductDownloadCountPerWeek <= 0">-->
                        <!--                Bạn đã hết lượt tải danh sách-->
                        <!--              </template>-->
                        <a-button class="relative" type="primary" size="large" @click="onClickBtnDownload('product')" ghost :disabled="isDownloadingSomething">
                          <a-icon :type="isDownloadingCSV ? 'loading' : 'download'"/>
                          Download danh sách
                          <div v-show="popupDownloadVisible === 'product' && isAvailableToDownloadProduct" class="absolute mt-2 select-option-tooltip-wrap">
                            <div class="relative select-option-tooltip shadow-md">
                              <a-icon type="close-circle" class="absolute top-0 right-0 mt-2 mr-2" @click.stop="popupDownloadVisible = null"/>
                              <p class="mb-3" v-if="PERMISSION[this.permissionAvailableName] && PERMISSION[this.permissionAvailableName].allLimitLineProductDownload && remainProductDownloadCountPerWeek < PERMISSION[this.permissionAvailableName].allLimitLineProductDownload[PERMISSION[this.permissionAvailableName].allLimitLineProductDownload.length - 1]">
                                <span class="text-gray-900">Bạn còn </span> {{remainProductDownloadCountPerWeek}} <span class="text-gray-900"> dòng data được download trong tuần</span>
                              </p>
                              <p class="text-lg text-gray-900">Tuỳ chọn:</p>
                              <div class="flex my-2">
                                <div style="width: 230px"><span class="text-gray-900">Giới hạn số sản phẩm  tải về :</span></div>
                                <a-select v-model="limitLineDownloadProduct" style="width: 80px;">
                                  <a-select-option v-for="limitOption in allLimitLineProductDownload" :key="limitOption" :value="limitOption">{{ limitOption }}</a-select-option>
                                </a-select>
                              </div>
                              <div class="flex my-2">
                                <div style="width: 230px"><span class="text-gray-900">Định dạnh tệp tải về:</span></div>
                                <a-select v-model="typeDownloadProduct" style="width: 80px;">
                                  <a-select-option v-for="typeOption in allTypeProductDownload" :key="typeOption" :value="typeOption">{{ typeOption }}</a-select-option>
                                </a-select>
                              </div>
                              <div class="flex">
                                <div style="width: 230px; height: 1px"></div>
                                <a-button type="primary" size="large" @click="onClickDownloadListProduct" :disabled="!limitLineDownloadProduct || !typeDownloadProduct || isDownloadingSomething">
                                  <div style="padding: 6px 4px 6px 4px;">
                                    <span>Download</span>
                                  </div>
                                </a-button>
                              </div>
                            </div>
                          </div>
                        </a-button>
                      </a-tooltip>
                    </div>
                  </div>
                  <a-spin :spinning="isLoadingProductData" style="min-height: 100px" v-show="productViewType === 'table'">
                    <TableProduct v-show="productViewType === 'table'" class=""/>
                  </a-spin>
                  <a-spin :spinning="isLoadingProductData" style="min-height: 100px" v-if="productViewType === 'grid'" class="mb-4">
                    <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4" style="">
                      <ProductItem class="hover:shadow-custom hover:border-primary-900" v-for="productItem in productsResult" :key="productItem.key" :product-item="productItem"/>
                      <div class="col-span-full flex justify-end">
                        <a-pagination @change="onChangeProductPagination" simple v-model="iPage" :total="240" :defaultPageSize="iLimit" :key="iLimit"/>
                      </div>
                    </div>
                  </a-spin>
                </a-tab-pane>
<!--                <a-tab-pane key="keyword-product" v-if="isHigherPriorityAccount(PERMISSION.market_default.priority) && freeSearchCount > 0">-->
<!--                  <h2 class="title" slot="tab">-->
<!--                    <span class="border-left-line"></span>-->
<!--                    Top từ khoá-->
<!--                  </h2>-->
<!--                  <TableKeyword/>-->
<!--                </a-tab-pane>-->
              </a-tabs>
            </div>
            <template v-if="isHigherPriorityAccount(PERMISSION.market_default.priority) && freeSearchCount > 0">
              <div class="flex justify-space-between margin-top-25 mb-2">
                <div class="flex items-center">
                  <h2 class="title">
                    <span class="border-left-line"></span>
                    Shop có Sản phẩm bán chạy
                  </h2>
                  <a-tooltip>
                    <template slot="title">
                      Hiển thị dạng bảng
                    </template>
                    <div class="margin-left-20 cursor-pointer p-1 rounded"
                       :class="shopViewType === 'table' ? 'text-white bg-primary-500' : 'text-gray-400 hover:bg-primary-custom'"
                         style="height: 32px;"
                    >
                      <IconList width="24" height="24"
                                @click="onClickChangeShopViewType('table')"
                      />
                    </div>
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">
                      So sánh tăng trưởng các shop
                    </template>
                    <div class="ml-2 p-1 rounded cursor-pointer"
                         :class="shopViewType === 'line-chart' ? 'text-white bg-primary-500' : 'text-gray-400 hover:bg-primary-custom'"
                         @click="onClickChangeShopViewType('line-chart')"
                    >
                      <a-icon type="line-chart"
                              :style="{fontSize: '24px'}"/>
                    </div>
                  </a-tooltip>
                </div>
                <a-tooltip placement="bottom" class="margin-left-10">
                  <!--                <template slot="title" v-if="remainShopDownloadCountPerWeek <= 0">-->
                  <!--                  Bạn đã hết lượt tải danh sách-->
                  <!--                </template>-->
                  <a-button type="primary" size="large" @click="onClickBtnDownload('shop')" ghost :disabled="isDownloadingSomething">
                    <a-icon :type="isDownloadingShop ? 'loading' : 'download'"/>
                    Download danh sách Shop
                    <div v-show="popupDownloadVisible === 'shop' && isAvailableToDownloadShop" class="absolute mt-2 select-option-tooltip-wrap">
                      <div class="relative select-option-tooltip shadow-md">
                        <a-icon type="close-circle" class="absolute top-0 right-0 mt-2 mr-2" @click.stop="popupDownloadVisible = null"/>
                        <p class="mb-3" v-if="PERMISSION[this.permissionAvailableName] && PERMISSION[this.permissionAvailableName].allLimitLineShopDownload && remainShopDownloadCountPerWeek < PERMISSION[this.permissionAvailableName].allLimitLineShopDownload[PERMISSION[this.permissionAvailableName].allLimitLineShopDownload.length - 1]">
                          <span class="text-gray-900">Bạn còn </span> {{remainShopDownloadCountPerWeek}} <span class="text-gray-900"> dòng data được download trong tuần</span>
                        </p>
                        <p class="text-lg text-gray-900">Tuỳ chọn:</p>
                        <div class="flex my-2">
                          <div style="width: 230px"><span class="text-gray-900">Giới hạn số sản phẩm  tải về:</span></div>
                          <a-select v-model="limitLineDownloadShop" style="width: 80px;">
                            <a-select-option v-for="(limitOption, index) in allLimitLineShopDownload" :key="limitOption" :value="limitOption">{{ limitOption }}</a-select-option>
                          </a-select>
                        </div>
                        <div class="flex my-2">
                          <div style="width: 230px"><span class="text-gray-900">Định dạnh tệp tải về:</span></div>
                          <a-select v-model="typeDownloadShop" style="width: 80px;">
                            <a-select-option v-for="typeOption in allTypeShopDownload" :key="typeOption" :value="typeOption">{{ typeOption }}</a-select-option>
                          </a-select>
                        </div>
                        <div class="flex">
                          <div style="width: 230px; height: 1px"></div>
                          <a-button type="primary" size="large" @click="onClickDownloadListShop" :disabled="!typeDownloadShop || !limitLineDownloadShop || isDownloadingSomething">
                            <div style="padding: 6px 4px 6px 4px;">
                              <span>Download</span>
                            </div>
                          </a-button>
                        </div>
                      </div>
                    </div>
                  </a-button>
                </a-tooltip>
              </div>
              <TableShop v-show="shopViewType === 'table'"/>
              <ShopChart v-if="shops && shops.length" :is-display="shopViewType === 'line-chart'" v-show="shopViewType === 'line-chart'"/>
            </template>
            <template v-if="isHigherPriorityAccount(PERMISSION.market_advance.priority) && freeSearchCount > 0">
              <div class="flex justify-space-between margin-top-25 mb-2">
                <div class="flex items-center">
                  <h2 class="title">
                    <span class="border-left-line"></span>
                    Top Thương hiệu
                  </h2>
                </div>
                <a-tooltip placement="bottom" class="margin-left-10">
                  <!--                <template slot="title" v-if="remainShopDownloadCountPerWeek <= 0">-->
                  <!--                  Bạn đã hết lượt tải danh sách-->
                  <!--                </template>-->
                  <a-button type="primary" size="large" @click="onClickBtnDownload('brand')" ghost :disabled="isDownloadingSomething">
                    <a-icon :type="isDownloadingBrand ? 'loading' : 'download'"/>
                    Download danh sách Thương hiệu
                    <div v-show="popupDownloadVisible === 'brand' && isAvailableToDownloadBrand" class="absolute mt-2 select-option-tooltip-wrap">
                      <div class="relative select-option-tooltip shadow-md">
                        <a-icon type="close-circle" class="absolute top-0 right-0 mt-2 mr-2" @click.stop="popupDownloadVisible = null"/>
<!--                        <p class="mb-3" v-if="PERMISSION[this.permissionAvailableName] && PERMISSION[this.permissionAvailableName].allLimitLineBrandDownload">-->
<!--                          <span class="text-gray-900">Bạn còn </span> {{remainShopDownloadCountPerWeek}} <span class="text-gray-900"> dòng data được download trong tuần</span>-->
<!--                        </p>-->
                        <p class="text-lg text-gray-900">Tuỳ chọn:</p>
                        <div class="flex my-2">
                          <div style="width: 230px"><span class="text-gray-900">Giới hạn số sản phẩm  tải về:</span></div>
                          <a-select v-model="limitLineDownloadBrand" style="width: 80px;">
                            <a-select-option v-for="(limitOption, index) in allLimitLineBrandDownload" :key="limitOption" :value="limitOption">{{ limitOption }}</a-select-option>
                          </a-select>
                        </div>
                        <div class="flex my-2">
                          <div style="width: 230px"><span class="text-gray-900">Định dạnh tệp tải về:</span></div>
                          <a-select v-model="typeDownloadBrand" style="width: 80px;">
                            <a-select-option v-for="typeOption in allTypeBrandDownload" :key="typeOption" :value="typeOption">{{ typeOption }}</a-select-option>
                          </a-select>
                        </div>
                        <div class="flex">
                          <div style="width: 230px; height: 1px"></div>
                          <a-button type="primary" size="large" @click="onClickDownloadListBrand" :disabled="!typeDownloadBrand || !limitLineDownloadBrand || isDownloadingSomething">
                            <div style="padding: 6px 4px 6px 4px;">
                              <span>Download</span>
                            </div>
                          </a-button>
                        </div>
                      </div>
                    </div>
                  </a-button>
                </a-tooltip>
              </div>
              <TableBrand/>
            </template>
          </div>
        </div>
      </a-spin>

      <div class="container-bg-white container-space-default border-solid" v-if="!responseStatus || !permissionAvailableName">
        <a-row :gutter="24" type="flex" class="product-empty" align="middle">
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="">
            <div class="content">Phân tích thị trường dựa trên phân tích Big Data</div>
    <!--          <a-button class="margin-top-15" type="primary" size="large" ghost>-->
    <!--            Xem hướng dẫn-->
    <!--          </a-button>-->
          </a-col>
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <div class="">
              <img src="@/assets/img/product/empty-product.svg" class="img-responsive cover">
            </div>
          </a-col>
        </a-row>
      </div>
    </Default>
    <MobileDefault v-else-if="isMobile">
      <SearchFilter @search="onCLickSearch" @reset="onCLickReset"/>
      <div>
        <template v-if="(waitingTransaction && waitingTransaction.length > 0) || (updatedTransaction && updatedTransaction.length > 0)">
          <div style="margin: 12px; text-align: center; margin-top: 16px; font-size: 12px" v-if="permissionAvailableName">
            <p v-if="waitingTransaction && waitingTransaction.length === 1">Bạn đang có {{ waitingTransaction.length }} lệch chờ xác nhận thanh toán, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail(waitingTransaction[0].voucher_id, waitingTransaction[0].voucher_code)">Xem chi tiết</span></p>
            <p v-if="waitingTransaction && waitingTransaction.length > 1">Bạn đang có {{ waitingTransaction.length }} lệch chờ xác nhận thanh toán, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail">Xem chi tiết</span></p>
            <template v-if="updatedTransaction && updatedTransaction.length === 1">
              <p v-if="updatedTransaction[0].isComplete">Bạn đã thanh toán thành công và được áp dụng gói cước, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail(updatedTransaction[0].voucher_id, updatedTransaction[0].voucher_code)">Xem chi tiết</span></p>
              <p v-else>Có lỗi xảy ra trong việc thanh toán gói cước của bạn, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail(updatedTransaction[0].voucher_id, updatedTransaction[0].voucher_code)">Xem chi tiết</span></p>
            </template>
            <template v-else-if="updatedTransaction && updatedTransaction.length > 1">
              <p>{{updatedTransaction.length}} Thanh toán của bạn đã được cập nhật trạng thái, <span class="text-highlight" style="cursor: pointer; text-decoration: underline" @click="onClickShowPaymentDetail">Xem chi tiết</span></p>
            </template>
          </div>
        </template>
        <template v-if="permissionAvailableName && !showPopupSystemError && isLogin && !this.isBusinessAccount && !this.isStandardAccount">
          <div v-if="typeof freeSearchCount === 'number' && freeSearchCount <= 10" style="text-align: center; margin: 16px 16px 12px 16px;">
            <p style="font-size: 14px;">
              <strong>{{accountName}}</strong> của bạn còn <span class="text-highlight" v-bind:style="{color: getSearchCountdownWarningColor(freeSearchCount)}">{{freeSearchCount}} lượt phân tích</span> trong hôm nay
              <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
            </p>
            <p style="margin-top: 8px; font-size: 12px">
              <span>Để không bị giới hạn này, hãy <span @click="onClickShowPricing" class="text-highlight" style="cursor: pointer;">Nâng cấp Tài khoản</span></span>
            </p>
          </div>
        </template>
        <template v-if="!showPopupSystemError && !isLogin">
          <div style="text-align: center; margin-top: 16px;">
            <p style="font-size: 14px;">
              Vui lòng <span class="text-highlight" @click="onClickLogin">đăng nhập</span> để <strong>Phân tích miễn phí</strong>
            </p>
          </div>
        </template>
      </div>
      <a-spin v-if="responseStatus && permissionAvailableName" :spinning="isClickSearch" class="report-content" id="mobile-report-wrap">
        <div class="container-bg-white container-space-mobile-default">
          <h2 class="page-title mobile-title">Báo cáo Phân tích thị trường</h2>
          <div class="duration-range-comparison-wrap" style="margin: 0 auto 8px auto" v-if="!isClickSearch && durationComparisonArray && durationComparisonArray.length">
            <div class="duration-range-comparison mobile-title" v-if="isBusinessAccount || isHigherPriorityAccount(PERMISSION.market_standard.priority)">
              <strong class="mb-1">So sánh với</strong>
              <div class="duration-range-comparison-option-container mobile-view">
                <div v-for="(item, index) in durationComparisonArray"
                     :key="index"
                     class="duration-range-comparison-option mobile-view"
                     :class="{'active': durationCompareIndex === index, 'disabled': !isBusinessAccount && !isHigherPriorityAccount(PERMISSION.market_standard.priority)}"
                     @click="onClickComparison(index)"
                >
                  {{item.label}}
                </div>
              </div>
            </div>
          </div>
          <h2 class="page-sub-title mobile-title" v-if="durationRangeOptionArray && durationRangeOptionArray[0] && durationRangeOptionArray[1]">
            <template v-if="!durationRangeOptionArray[0].isSame(durationRangeOptionArray[1])">
              Từ {{ durationRangeOptionArray[0].format('DD-MM-YYYY') }} đến
              {{ durationRangeOptionArray[1].format('DD-MM-YYYY') }}
            </template>
            <template v-else>
              Cho ngày {{ durationRangeOptionArray[0].format('DD-MM-YYYY') }}
            </template>
          </h2>
          <h2 class="page-sub-title mobile-title" v-if="isBusinessAccount && typeof durationCompareIndex === 'number' && durationComparisonArray && durationComparisonArray.length">
            <template v-if="!durationComparisonArray[durationCompareIndex].value[0].isSame(durationComparisonArray[durationCompareIndex].value[1])">
              So sánh với {{durationComparisonArray[durationCompareIndex].value[0].format('DD-MM-YYYY')}} đến {{durationComparisonArray[durationCompareIndex].value[1].format('DD-MM-YYYY')}}
            </template>
            <template v-else>
              So sánh với ngày {{durationComparisonArray[durationCompareIndex].value[0].format('DD-MM-YYYY')}}
            </template>
          </h2>
          <div class="" v-if="!isClickSearch">
            <StatisticGeneral @mounted="onMountedStatisticGeneral"/>
          </div>
        </div>
        <div v-if="!isClickSearch">
          <MarketChartV2 :is-comparison-mode="typeof durationCompareIndex === 'number'" :is-search-demo-init="isSearchDemoInit"/>
          <div id="product-section-mobile">
            <div class="container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
              <h2 class="title mobile-title flex flex-row justify-space-between items-center">
                <div>
                  <span class="border-left-line mobile-view"></span>
                  <span>Sản phẩm bán chạy</span>
                </div>
                <div class="mt-1 flex" v-if="!isAvailableToDownloadProduct">
                  <div @click="productViewType = 'table'"
                       class="mr-3 p-1 cursor-pointer"
                       :class="productViewType === 'table' ? 'text-white bg-primary-500 rounded' : 'text-gray-400 hover:bg-primary-custom'"
                       style="height: 28px"
                  >
                    <IconList width="20" height="20"/>
                  </div>
                  <div class="p-1 cursor-pointer"
                       :class="productViewType === 'grid' ? 'text-white bg-primary-500 rounded' : 'text-gray-400 hover:bg-primary-custom'"
                       style="height: 28px;"
                       @click="productViewType = 'grid'"
                  >
                    <Icon4Square width="20" height="20"/>
                  </div>
                </div>
              </h2>
              <div class="flex justify-space-between items-center">
                <a-button class="relative mt-2" type="primary" size="small" ghost
                          @click="onClickBtnDownload('product')"
                          v-if="!isAvailableToDownloadProduct"
                          :disabled="isDownloadingSomething"
                          style="font-size: 12px; height: 28px;"
                >
                  <a-icon :type="isDownloadingCSV ? 'loading' : 'download'"/>
                  <span>Download danh sách</span>
                </a-button>
                <div v-else class="mt-2 flex">
                  <div @click="productViewType = 'table'"
                       class="mr-3 p-1 cursor-pointer"
                       :class="productViewType === 'table' ? 'text-white bg-primary-500 rounded' : 'text-gray-400 hover:bg-primary-custom'"
                       style="height: 28px"
                  >
                    <IconList width="20" height="20"/>
                  </div>
                  <div class="p-1 cursor-pointer"
                       :class="productViewType === 'grid' ? 'text-white bg-primary-500 rounded' : 'text-gray-400 hover:bg-primary-custom'"
                       style="height: 28px;"
                       @click="productViewType = 'grid'"
                  >
                    <Icon4Square width="20" height="20"/>
                  </div>
                </div>
                <i @click="onClickProductSortPopup">
                  <IconSort width="20" height="20"/>
                </i>
                <PopupSortMobile
                  :visible="popupSortProductVisible"
                  :sort-metric-array="sortMetricArray"
                  :sort-metric-default="sortMetricDefault"
                  @select="handleSelectSort"
                  @close="popupSortProductVisible=false"
                />
              </div>
            </div>
            <a-spin :spinning="isLoadingProductData" style="min-height: 100px" v-show="productViewType === 'table'">
              <TableProduct class="container-bg-white"
                            style="margin: 0 12px"
                            :load-type="null"
              />
            </a-spin>
            <a-spin :spinning="isLoadingProductData" style="min-height: 100px" v-if="productViewType === 'grid'" class="container-bg-white mx-3 px-2 py-3">
              <div class="grid grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-4 xl:grid-cols-4" style="">
                <ProductItemMobile class="hover:shadow-custom hover:border-primary-900" v-for="productItem in productsResult" :key="productItem.key" :product-item="productItem"/>
              </div>
            </a-spin>
            <Pagination :current-page="iPage" :total-page="20"
              v-if="isMobile && isHigherPriorityAccount(PERMISSION.market_default.priority) && freeSearchCount > 0"
              class="container-bg-white px-2 py-3 text-sm" style="color: #8C8C8C; font-size: 8px; margin: 0 12px"
                        @change="onChangeProductPagination"
            />
          </div>
<!--          <template v-if="isHigherPriorityAccount(PERMISSION.market_default.priority) && freeSearchCount > 0">-->
<!--            <div class="">-->
<!--              <div class="flex flex-row justify-space-between items-center container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">-->
<!--                <h2 class="title mobile-title">-->
<!--                  <span class="border-left-line mobile-view"></span>-->
<!--                  Top từ khoá-->
<!--                </h2>-->
<!--    &lt;!&ndash;              <a-icon type="filter" style="margin-bottom: 8px; color: #838383"/>&ndash;&gt;-->
<!--              </div>-->
<!--              <TableKeyword class="container-bg-white" style="margin: 0 12px" :load-type="null" row-count="5"/>-->
<!--              <div v-if="isMobile" slot="show_more" class="container-bg-white text-right mb-5" style="color: #8C8C8C; font-size: 8px; padding: 15px 12px 10px 0; margin: 0 12px">-->
<!--                <span class="cursor-pointer" @click="onClickShowMore('table_keyword_view')">Xem thêm</span>-->
<!--                <a-icon type="right" />-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
          <template v-if="isHigherPriorityAccount(PERMISSION.market_default.priority) && freeSearchCount > 0">
            <div class="">
              <div class="flex flex-row justify-space-between items-center container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
                <h2 class="title mobile-title">
                  <span class="border-left-line mobile-view"></span>
                  Shop có Sản phẩm bán chạy
                </h2>
                <!--              <a-icon type="filter" style="margin-bottom: 8px; color: #838383"/>-->
              </div>
              <TableShop class="container-bg-white" style="margin: 0 12px" row-count="5"/>
            </div>
          </template>
          <template v-if="isHigherPriorityAccount(PERMISSION.market_advance.priority) && freeSearchCount > 0">
            <div class="">
              <div class="flex flex-row justify-space-between items-center container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
                <h2 class="title mobile-title">
                  <span class="border-left-line mobile-view"></span>
                  Top Thương hiệu
                </h2>
                <!--              <a-icon type="filter" style="margin-bottom: 8px; color: #838383"/>-->
              </div>
              <TableBrand class="container-bg-white" style="margin: 0 12px" row-count="10"/>
            </div>
          </template>
        </div>
      </a-spin>
      <div class="container-bg-white container-space-mobile-default border-solid" v-if="!responseStatus || !permissionAvailableName">
        <a-row :gutter="24" type="flex" class="product-empty" style="margin: 16px !important;" align="middle">
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="">
            <div class="">Phân tích thị trường dựa trên phân tích Big Data</div>
          </a-col>
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <div class="">
              <img src="@/assets/img/product/empty-product.svg" class="img-responsive cover">
            </div>
          </a-col>
        </a-row>
      </div>
    </MobileDefault>
    <PopupChatToDownload :source="popupDownloadVisible"/>
    <PopupNotification v-if="showPopupNotification === NOTIFICATION_TYPE.create_saved_report"
                        title="Tạo Báo cáo"
                       :contents="['Vui lòng thực hiện lệnh Phân tích và Lưu Báo cáo']"
                       @ok="$router.push({query: {popup: undefined}})"
                       @cancel="$router.push({query: {popup: undefined}})"
    />
  </div>
</template>

<script>
  import Default from '@/layout/Default'
  import TableProduct from '@/views/search/TableProduct'
  import MetricRectangle from '@/components/statistic/metric/MetricRectangle'
  import { formatCurrency, formatNumberHuman } from '@/helper/FormatHelper'
  import SearchFilter from '@/views/search/SearchFilter'
  import { MutationSidebar } from '@/store/modules/SidebarModule/mutation'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import {ActionNameConstants, NOTIFICATION_TYPE} from '@/constant/general/GeneralConstant'
  import {TRANSACTION_STATE} from '@/constant/pricing/PricingPlansConstant'
  import BarChart from '@/components/chart/BarChart'
  import LineChart from '@/components/chart/LineChart'
  import ColumnChart from '@/components/chart/ColumnChart'
  import PieChart from '@/components/chart/PieChart'
  import {
    getListVoucherWaiting, isLogined,
    shareCustomQuery
  } from '@/service/user/UserProfileService'
  import StatisticGeneral from '@/views/market/StatisticGeneral'
  import {downloadFile, getFileProductData, getListShopData} from "@/service/market/MarketService";
  import {URL_WEB} from "@/config/ConfigEndpoint";
  import {apiSearchDemo} from "@/api/product/ApiProductSearch";
  import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
  import {findPlanInfo, getListTransaction} from "@/service/payment/PaymentService";
  import {
    getDateFromOption,
    getDurationPrevious,
    getDurationPreviousQuarter,
    getDurationRange, getSearchConfig, isOverRange
  } from "@/service/search/SearchService";
  import moment from "moment";
  import {
    mixinStoreGetterUser,
    mixinStoreGetterPayment,
    mixinStoreGetterPermission,
  } from "@/mixin/store/MixinUser";
  import {MutationSearchStatisticComparisonResult} from "@/store/modules/SearchStatisticComparisonResultModule/mutation";
  import {fbTrackingSearch} from "@/service/tracking/FacebookTrackingService";
  import {ALL_FIELD_FILTER, ALL_FIXED_DURATION_DAY} from "@/constant/search/SearchConstant";
  import MarketChartV2 from "@/views/market/MarketChartV2";
  import {getPlatformById} from "@/service/platform/PlatformCommonService";
  import {PERMISSION} from "@/constant/general/PermissionConstant";
  import TableShop from "@/views/search/TableShop";
  import TableKeyword from "@/views/search/TableKeyword";
  import PopupChatToDownload from "@/views/popup/PopupChatToDownload";
  import MobileDefault from "@/layout/MobileDefault";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import ShopChart from "@/components/chart/ShopChart";
  import {mixinStoreGetterShops} from "@/mixin/store/MixinShops";
  import {MutationSearchShopsResult} from "@/store/modules/SearchShopsResultModule/mutation";
  import {MutationSearchKeywordsResult} from "@/store/modules/SearchKeywordsResultModule/mutation";
  import {mixinStoreGetterProducts} from "@/mixin/store/MixinProducts";
  import ProductItem from "@/components/product/ProductItem";
  import Icon4Square from "@/assets/icon/Icon4Square";
  import IconList from "@/assets/icon/IconList";
  import Pagination from "@/components/common/Pagination";
  import ProductItemMobile from "@/components/product/ProductItemMobile";
  import IconSort from "@/assets/icon/IconSort";
  import PopupSortMobile from "@/views/popup/PopupSortMobile";
  import {toNumber} from "@/helper/StringHelper";
  import SaveReportComponent from "@/components/report/SaveReportComponent";
  import PopupNotification from "@/views/popup/PopupNotification";
  import {apiDownloadGroupSearch} from "@/api/market/ApiMarket";
  import TableBrand from "@/views/table/TableBrand";
  import {MutationSearchBrandsResult} from "@/store/modules/SearchBrandsResultModule/mutation";
  import IconNotification from "@/assets/icon/IconNotification";
  import {BRAND_NAME, SOLOGAN} from "@/constant/general/GeneralConstant";

  export default {
    components: {
      Default,
      MobileDefault,
      SearchFilter,
      TableProduct,
      MetricRectangle,
      BarChart,
      LineChart,
      ColumnChart,
      PieChart,
      MarketChartV2,
      StatisticGeneral,
      TableShop,
      TableKeyword,
      TableBrand,
      PopupChatToDownload,
      ShopChart,
      ProductItem,
      ProductItemMobile,
      Icon4Square,
      IconList,
      IconSort,
      Pagination,
      PopupSortMobile,
      SaveReportComponent,
      PopupNotification,
      IconNotification,
    },
    data() {
      return {
        tabStatistic: 'revenue',
        hash: this.$route.query.hash,
        searchOptions: null,
        url: null,
        isLoadingShareCustomQuery: false,
        isDownloadingCSV: false,
        isDownloadingShop: false,
        isDownloadingBrand: false,
        popupDownloadVisible: null,
        limitLineDownloadProduct: null,
        typeDownloadProduct: null,
        limitLineDownloadShop: null,
        limitLineDownloadBrand: null,
        typeDownloadShop: null,
        typeDownloadBrand: null,
        waitingTransaction: 0,
        updatedTransaction: null,
        durationCompareIndex: null,
        durationComparisonArray: null,
        durationRangeOptionArray: null,
        isClickSearch: false,
        isSearchDemoInit: false,
        PERMISSION: PERMISSION,
        activeTab: 'table-product',
        showPopupTableFullProduct: false,
        shopViewType: 'table',
        productViewType: 'table',
        allProductSort: [],
        productSortValue: null,
        productSortDirection: null,
        isLoadingProductData: false,
        popupSortProductVisible: false,
        NOTIFICATION_TYPE,
        isMultiplePlatform: false,
        BRAND_NAME,
        SOLOGAN,
      }
    },
    async mounted() {
      // if (typeof this.hash === 'string') {
      //   this.$router.push({name: 'custom_market_view', params: {hash: this.hash}})
      //   return
      // }
      window.scroll(0, 0)
      if (await isLogined()) {
        let allPayment = await getListTransaction()
        this.waitingTransaction = allPayment?.filter(transaction => transaction.state === TRANSACTION_STATE.WAITING)
        this.updatedTransaction = await this.getUpdateStateTransaction(allPayment)
      }
      let popup = this.$route.query['popup']
      this.showPopupNotification = popup === NOTIFICATION_TYPE.create_saved_report ? NOTIFICATION_TYPE.create_saved_report : ''
      let urlShop = this.$route.query['shop_url']
      let savedReportHash = this.$route.query['saved-report']
      if (!urlShop && !savedReportHash) {
        this.isSearchDemoInit = true
        await this.updateSearchConfig()
        this.updateState()
      }
    },
    created() {
      // update state for UI
      // Cập nhật trạng thái {menu, actionName} đang ở màn hình search
      this.$store.commit(`GeneralModule/${MutationGeneral.setActionName}`, ActionNameConstants.MARKET)
      this.$store.commit(`SidebarModule/${MutationSidebar.menuSelected}`, ActionNameConstants.MARKET)
    },

    methods: {
      onCLickSearch(value){
        this.isSearchDemoInit = false
        this.isClickSearch = value
        if (value) {
          this.shopViewType = 'table'
          this.productViewType = 'table'
          this.$store.dispatch(`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.RESET}`)
          this.$store.dispatch(`${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.RESET}`)
          this.$store.dispatch(`${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.RESET}`)
        }
        this.isMultiplePlatform = this.options?.include_query?.platforms?.length > 1
      },
      onCLickReset(value){
        this.isSearchDemoInit = true
        this.isClickSearch = value
        if (value) {
          this.shopViewType = 'table'
          this.productViewType = 'table'
          this.$store.dispatch(`${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.RESET}`)
          this.$store.dispatch(`${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.RESET}`)
          this.$store.dispatch(`${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.RESET}`)
        }
      },
      async updateSearchConfig() {
        let searchConfig = await getSearchConfig(this.iPlatforms?.[0])
        this.searchConfig = {
          startDate: getDateFromOption(searchConfig?.start_date) || moment('2020 01 01', 'YYYY MM DD')
            .toDate(),
          endDate: getDateFromOption(searchConfig?.current_date) || moment()
            .toDate()
        }
        if (!this.isSavedReport) {
          this.iDurationDay = this.iDurationDay ? this.iDurationDay : ALL_FIXED_DURATION_DAY[0]
          this.iDurationRange = getDurationRange(this.iDurationDay, this.searchConfig.endDate)
        }
      },
      getSearchCountdownWarningColor(remainSearchCount){
        if (remainSearchCount <= 1){
          return "#ee0033"
        }
        if (remainSearchCount <= 3){
          return "#FFB655"
        }
        return "#008000"
      },
      async onClickShowNoti() {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
      },
      async onClickShowPricing() {
        this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
        // await this.$router.push({name: 'pricing'})
      },
      async onClickShowPaymentDetail(voucherId, voucherCode) {
        if (!voucherCode || !voucherId) {
          this.$router.push({name: 'user_view'})
          return
        }
        this.$router.push({name: 'transaction_detail', params: {voucherId: voucherId, voucherCode: voucherCode}})
      },
      async onClickDownloadListProduct() {
        this.popupDownloadVisible = null
        this.isDownloadingCSV = true
        if (!this.allLimitLineProductDownload?.[0] || !this.allTypeProductDownload?.[0]) {
          return
        }
        if (!this.isLogin) {
          // this.$notification['error']({
          //   message: 'Đăng nhập để tiếp tục',
          //   description: 'Chức năng này cần đăng nhập',
          // })
        this.$router.push({ name: 'login' })
          this.isDownloadingCSV = false
          return
        }
        if (this.isExpired || !this.isHigherPriorityAccount(PERMISSION.market_default.priority)) {
          // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
          // this.$notification['error']({
          //   message: 'Hãy nâng cấp tài khoản',
          //   description: 'Tài khoản của bạn cần nâng cấp để tải danh sách sản phẩm',
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
          this.isDownloadingCSV = false
          return
        }
        // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        if (options.durationDay !== 0) {
          delete options.start_date
          delete options.end_date
        }
        options.limit_line = this.limitLineDownloadProduct || this.allLimitLineProductDownload[0]
        options.file_type = this.typeDownloadProduct || this.allTypeProductDownload[0]
        // delete options.durationDay
        let data = await getFileProductData(options)
        let platformName = getPlatformById(options.include_query.platforms[0]).name
        downloadFile(data, `beecost-market-${platformName}-${this.durationDayString}`, options.file_type === 'csv' ? 'csv' : 'xlsx')
        this.isDownloadingCSV = false
      },
      async onClickDownloadListShop() {
        this.popupDownloadVisible = null
        this.isDownloadingShop = true
        if (!this.allLimitLineShopDownload?.[0] || !this.allTypeShopDownload?.[0]) {
          return
        }
        if (!this.isLogin) {
          // this.$notification['error']({
          //   message: 'Đăng nhập để tiếp tục',
          //   description: 'Chức năng này cần đăng nhập',
          // })
        this.$router.push({ name: 'login' })
          this.isDownloadingShop = false
          return
        }
        if (this.isExpired || !this.isHigherPriorityAccount(PERMISSION.market_basic.priority)) {
          // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
          // this.$notification['error']({
          //   message: 'Hãy nâng cấp tài khoản',
          //   description: 'Tài khoản của bạn cần nâng cấp lên Tài Khoản Doanh nghiệp để tải danh sách shop',
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
          this.isDownloadingShop = false
          return
        }
        // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        if (options.durationDay !== 0) {
          delete options.start_date
          delete options.end_date
        }
        options.limit_line = this.limitLineDownloadShop || this.allLimitLineShopDownload[0]
        options.file_type = this.typeDownloadShop || this.allTypeShopDownload[0]
        // delete options.durationDay
        let data = await getListShopData(options)
        let platformName = getPlatformById(options.include_query.platforms[0]).name
        downloadFile(data, `beecost-market-shop-list-${platformName}-${this.durationDayString}`, options.file_type === 'csv' ? 'csv' : 'xlsx')
        this.isDownloadingShop = false
      },
      async onClickDownloadListBrand() {
        this.popupDownloadVisible = null
        this.isDownloadingBrand = true
        if (!this.isLogin) {
        this.$router.push({ name: 'login' })
          this.isDownloadingBrand = false
          return
        }
        if (!this.isAvailableToDownloadBrand) {
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
          this.isDownloadingBrand = false
          return
        }
        // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        if (options.durationDay !== 0) {
          delete options.start_date
          delete options.end_date
        }
        options.limit_line = this.limitLineDownloadBrand || this.allLimitLineBrandDownload[0]
        options.file_type = this.typeDownloadBrand || this.allTypeBrandDownload[0]
        // delete options.durationDay
        let data = await apiDownloadGroupSearch(options, 'brand')
        let platformName = getPlatformById(options.include_query.platforms[0]).name
        downloadFile(data, `beecost-market-brand-list-${platformName}-${this.durationDayString}`, options.file_type === 'csv' ? 'csv' : 'xlsx')
        this.isDownloadingBrand = false
      },
      onClickBtnPromote() {
        this.$router.push({ name: 'referral_program' })
        // this.showPopupPromotedNotification = true
      },
      async onShareReport() {
        this.isLoadingShareCustomQuery = true
        if (document.location.href.includes('hash=')) {
          this.url = document.location.href
          this.$store.dispatch(`GeneralModule/${MutationGeneral.setShowPopupShareLink}`, true)
          this.isLoadingShareCustomQuery = false
          return
        }
        let options = this.$store.state.SearchFilterModule.options
        const hashString = await shareCustomQuery(options)
        if (!hashString) {
          this.$notification['error']({
            message: 'Đã xảy ra lỗi',
            description: 'Chức năng này chỉ dành cho tài khoản cao cấp',
          })
          this.isLoadingShareCustomQuery = false
          return
        }
        this.url = this.$router
        this.url = `${URL_WEB}/insight/${hashString}`
        this.isLoadingShareCustomQuery = false
        this.$store.dispatch(`GeneralModule/${MutationGeneral.setShowPopupShareLink}`, true)
      },
      async getUpdateStateTransaction(latestTransactionState) {
        let voucherCodeLocal = await getListVoucherWaiting()
        if (!voucherCodeLocal?.length) {
          return null
        }
        return latestTransactionState
          ?.filter(updatedTransaction => {
            if (voucherCodeLocal.includes(updatedTransaction.voucher_code) && updatedTransaction.state !== TRANSACTION_STATE.WAITING) {
              return true
            }
            return false
          })
        ?.map(transaction => {
          let plan = findPlanInfo(transaction.voucher_type)
          let id = plan?.name ? plan.type : plan.id
          return {
            voucher_id: id,
            isComplete: transaction.state === TRANSACTION_STATE.COMPLETE,
            ...transaction
          }
        })
      },
      async onClickComparison(index) {
        if ((!this.isBusinessAccount && !this.isHigherPriorityAccount(PERMISSION.market_standard.priority)) || this.isLoading || this.durationCompareIndex === index) {
          return
        }
        this.isSearchDemoInit = false
        this.durationCompareIndex = index
        this.isClickSearch = true
        // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
        let optionsFilter = this.$store.state.SearchFilterModule.options
        // if (optionsFilter.durationDay) {
        //   let optionsText = JSON.stringify(optionsFilter)
        //   optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}d`, 'custom')
        //   optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}D`, 'CUSTOM')
        //   optionsFilter = JSON.parse(optionsText)
        // }
        let durationRange = this.durationComparisonArray[index]?.value
        if (durationRange && durationRange[0] && durationRange[1]) {
          optionsFilter = {
            ...optionsFilter,
            'start_date': durationRange[0].format("YYYY MM DD").replaceAll(' ', ''),
            'end_date': durationRange[1].format("YYYY MM DD").replaceAll(' ', ''),
            'durationDay': 0
          }
          await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iDurationRangeComparison}`, durationRange)
        }
        let optionsGeneralStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_GENERAL_STATISTIC}`, optionsFilter)
        // let optionsChartStatistic = this.iPlatforms[0] === ALL_PLATFORM_BASE_OBJECT.lazada.platform_id
        //   ? await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVIEW_COUNT_STATISTIC}`, optionsFilter)
        //   : await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVENUE_STATISTIC}`, optionsFilter)
        let optionsChartStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVENUE_STATISTIC}`, optionsFilter)
        let options = {
          ...optionsFilter,
          'statistics': [
            ...optionsGeneralStatistic,
            ...optionsChartStatistic
          ]
        }
        options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT_COMPARISON
        await this.$store.dispatch(
          `${MutationSearchStatisticComparisonResult.MODULE_PATH}/${MutationSearchStatisticComparisonResult.setTextComparison}`,
          this.durationComparisonArray[index]?.label
        )
        this.isClickSearch = !await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        fbTrackingSearch()
      },
      getDurationComparisonArray() {
        let result = []
        if (!this.searchConfig || !this.durationRangeOptionArray) {
          return null
        }
        let fixedRange = [this.searchConfig?.startDate, this.searchConfig?.endDate]
        let previous = getDurationPrevious(this.durationRangeOptionArray)
        if (previous && !isOverRange(previous.value, fixedRange)) {
          result.push(previous)
        }
        let previousQuarter = getDurationPreviousQuarter(this.durationRangeOptionArray)
        if (previousQuarter && !isOverRange(previousQuarter.value, fixedRange)) {
          result.push(previousQuarter)
        }
        return result
      },
      getDurationRangeOptionArray() {
        let options = this.$store.state.SearchFilterModule.options
        let durationDay = options.durationDay || ALL_FIXED_DURATION_DAY[1]
        let startDate = options[`start_date`]
        let endDate = options[`end_date`]
        if (!startDate || !endDate) {
          return getDurationRange(durationDay, this.searchConfig?.endDate)
        }
        startDate = moment(getDateFromOption(startDate))
        endDate = moment(getDateFromOption(endDate))
        if (!startDate || !endDate) {
          return getDurationRange(durationDay, this.searchConfig?.endDate)
        }
        return [startDate, endDate]
      },
      updateState() {
        this.durationRangeOptionArray = this.getDurationRangeOptionArray()
        this.durationComparisonArray = this.getDurationComparisonArray()
      },
      onMountedStatisticGeneral() {
        if (this.isSearchDemoInit) {
          return
        }
        const reportWrapElem = this.isDesktop ? document.getElementById('report-wrap') : document.getElementById('mobile-report-wrap')
        reportWrapElem.scrollIntoView();
        // reportWrapElem.scrollIntoView({behavior: "smooth"}); //bug for chrome: not scroll
      },
      onClickBtnDownload(type) {
        this.popupDownloadVisible = type
        switch (type) {
          case 'product':
            if (!this.isAvailableToDownloadProduct) {
              this.showPopupChatToDownload = true
              return
            }
            this.limitLineDownloadProduct = this.allLimitLineProductDownload?.[0]
            this.typeDownloadProduct = this.allTypeProductDownload?.[0]
            break
          case 'shop':
            if (!this.isAvailableToDownloadShop) {
              this.showPopupChatToDownload = true
              return
            }
            this.limitLineDownloadShop = this.allLimitLineShopDownload?.[0]
            this.typeDownloadShop = this.allTypeShopDownload?.[0]
            break
          case 'brand':
            if (!this.isAvailableToDownloadBrand) {
              this.showPopupChatToDownload = true
              return
            }
            this.limitLineDownloadBrand = this.allLimitLineBrandDownload?.[0]
            this.typeDownloadBrand = this.allTypeBrandDownload?.[0]
            break
        }
      },
      onClickShowMore(pageTableName) {
        this.$router.push({name: pageTableName})
      },
      async onClickLogin() {
        this.$router.push({ name: 'login' })
      },
      async onClickChangeShopViewType(shopViewType) {
        if (!this.isBusinessAccount) {
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_business_permission)
          return
        }
        this.shopViewType = shopViewType
      },
      updateProductSort(isDefault = false) {
        let durationDay = this.$store.state.SearchFilterModule.options?.durationDay
        let sortBy = isDefault ? this.$store.state.SearchFilterModule.options?.sort_by : this.$store.state.SearchFilterModule.iSortBy
        if (!sortBy) {
          this.productSortValue = durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom`
          this.productSortDirection = 'desc'
          this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, `${this.productSortValue}__${this.productSortDirection}`)
          return
        }
        [this.productSortValue, this.productSortDirection] = sortBy.split('__')
      },
      resetProductSort() {
        this.updateProductSort(true)
        this.updateProductResult()
      },
      onChangeProductSort() {
        this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, `${this.productSortValue}__${this.productSortDirection}`)
        this.updateProductResult()
      },
      async updateProductResult() {
        this.isLoadingProductData = true
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, !this.isLogin)
        await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        this.isLoadingProductData = false
      },
      onChangeProductPagination(page, pageSize) {
        let productSection = document.getElementById('product-section') || document.getElementById('product-section-mobile')
        productSection?.scrollIntoView()
        if (page) {
          this.iPage = page
        }
        if (pageSize) {
          this.iLimit = pageSize
        }
        this.updateProductResult()
      },
      async onChangeLimitNumberProduct() {
        await this.updateProductResult()
      },

      onClickProductSortPopup() {
        this.popupSortProductVisible = true
      },
      handleSelectSort(value) {
        this.popupSortProductVisible = false
        if (!value || value === this.sortMetricDefault) {
          return
        }
        this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, value)
        this.updateProductResult()
      }
    },
    computed: {
      isAvailableToDownloadProduct() {
        return this.allLimitLineProductDownload?.length && this.remainProductDownloadCountPerWeek > 0
      },
      isAvailableToDownloadShop() {
        return this.allLimitLineShopDownload?.length && this.remainShopDownloadCountPerWeek > 0
      },
      isAvailableToDownloadBrand() {
        return this.allLimitLineShopDownload?.length
          && this.remainShopDownloadCountPerWeek > 0
          && !this.isExpired
          && this.isHigherPriorityAccount(PERMISSION.market_advance.priority)
      },
      isDownloadingSomething() {
        return this.isDownloadingCSV || this.isDownloadingShop || this.isDownloadingBrand
      },
      responseStatus() {
        return this.$store.getters["SearchStatisticResultModule/status"]
      },
      durationDayString: {
        get() {
          let startDate = moment(this.durationRangeOptionArray[0]).format('DD_MM_YYYY')
          let endDate = moment(this.durationRangeOptionArray[1]).format('DD_MM_YYYY')
          return `${startDate}-${endDate}`
        },
      },
      iPlatforms: {
        get() {
          return this.$store.getters[`${MutationSearchFilter.MODULE_PATH}/iPlatforms`]
        }
      },
      options: {
        get() {
          return this.$store.state.SearchFilterModule.options
        }
      },
      searchConfig: {
        get() {
          return this.$store.getters[`${MutationSearchFilter.MODULE_PATH}/searchConfig`]
        },
        async set(value) {
          await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.searchConfig}`, value)
        }
      },
      iDurationDay: {
        get() {
          return this.$store.state.SearchFilterModule.iDurationDay
        },
        async set(value) {
          await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iDurationDay}`, value)
        }
      },
      iDurationRange: {
        get() {
          return this.$store.getters["SearchFilterModule/iDurationRange"]
        },
        async set(value) {
          await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iDurationRange}`, value)
        }
      },
      iLimit: {
        get() {
          return this.$store.getters["SearchFilterModule/iLimit"]
        },
        async set(value) {
          await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iLimit}`, value)
        }
      },
      iPage: {
        get() {
          return this.$store.getters["SearchFilterModule/iPage"]
        },
        async set(value) {
          await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iPage}`, value)
        }
      },
      isSavedReport: {
        get() {
          return this.$store.getters[`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.isSavedReport}`]
        }
      },
      isLoading: {
        get() {
          return this.$store.state.SearchFilterModule.isLoading
        }
      },
      comparisonStatus: {
        get() {
          return this.$store.getters["SearchStatisticComparisonResultModule/status"]
        }
      },
      showPopupSystemError: {
        get() {
          return this.$store.state.GeneralModule.showPopupSystemError
        }
      },
      showPopupPromotedNotification: {
        get() {
          return this.$store.state.GeneralModule.showPopupPromotedNotification
        },
        async set(value) {
          await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPromotedNotification}`, value)
        }
      },
      allLimitLineProductDownload: {
        get() {
          if (!PERMISSION[this.permissionAvailableName]?.allLimitLineProductDownload?.length) {
            return []
          }
          let result = PERMISSION[this.permissionAvailableName].allLimitLineProductDownload.filter(item => item < this.remainProductDownloadCountPerWeek)
          if (this.remainProductDownloadCountPerWeek > 0 && result?.length <=0 ) {
            return [this.remainProductDownloadCountPerWeek]
          }
          return result
        }
      },
      allTypeProductDownload: {
        get() {
          return PERMISSION[this.permissionAvailableName]?.allTypeProductDownload
        }
      },
      allLimitLineShopDownload: {
        get() {
          if (!PERMISSION[this.permissionAvailableName]?.allLimitLineShopDownload?.length) {
            return []
          }
          let result = PERMISSION[this.permissionAvailableName].allLimitLineShopDownload.filter(item => item < this.remainShopDownloadCountPerWeek)
          if (this.remainShopDownloadCountPerWeek > 0 && result?.length <=0 ) {
            return [this.remainShopDownloadCountPerWeek]
          }
          return result
        }
      },
      allTypeShopDownload: {
        get() {
          return PERMISSION[this.permissionAvailableName]?.allTypeShopDownload
        }
      },
      allLimitLineBrandDownload: {
        get() {
          if (!PERMISSION[this.permissionAvailableName]?.allLimitLineBrandDownload?.length) {
            return []
          }
          // let result = PERMISSION[this.permissionAvailableName].allLimitLineBrandDownload.filter(item => item < this.remainShopDownloadCountPerWeek)
          // if (this.remainShopDownloadCountPerWeek > 0 && result?.length <=0 ) {
          //   return [this.remainShopDownloadCountPerWeek]
          // }
          let result = PERMISSION[this.permissionAvailableName].allLimitLineBrandDownload
          return result
        }
      },
      allTypeBrandDownload: {
        get() {
          return PERMISSION[this.permissionAvailableName]?.allTypeBrandDownload
        }
      },
      showPopupChatToDownload: {
        get() {
          return !!this.$store.getters[`${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupChatToDownload}`]
        },
        async set(value) {
          await this.$store.commit(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupChatToDownload}`, value)
        }
      },
      sortMetricArray() {
        let product = this.productsResult?.[0]
        if (!product) {
          return null
        }
        let metricArray = []
        for (const productKey in product) {
          let durationDay = toNumber(productKey)
          switch (true) {
            case productKey === 'price':
              metricArray.push({
                label: `Giá`,
                id: productKey,
                optionArray: [
                  {
                    label: 'Cao > Thấp',
                    id: 'desc'
                  },
                  {
                    label: 'Thấp > Cao',
                    id: 'asc'
                  },
                ]
              })
              break
            case productKey === `order_count_${durationDay}d`:
            case productKey === `order_count_custom`:
              metricArray.push({
                label: `Đã bán ${ durationDay ? `${durationDay} ngày` : '' }`,
                id: productKey,
                optionArray: [
                  {
                    label: 'Cao > Thấp',
                    id: 'desc'
                  },
                  {
                    label: 'Thấp > Cao',
                    id: 'asc'
                  },
                ]
              })
              break
            case productKey === `order_revenue_${durationDay}d`:
            case productKey === `order_revenue_custom`:
              metricArray.push({
                label: `Doanh số ${ durationDay ? `${durationDay} ngày` : '' }`,
                id: productKey,
                optionArray: [
                  {
                    label: 'Cao > Thấp',
                    id: 'desc'
                  },
                  {
                    label: 'Thấp > Cao',
                    id: 'asc'
                  },
                ]
              })
              break
            case productKey === 'product_name':
              metricArray.push({
                label: 'Tên sản phẩm',
                id: productKey,
                optionArray: [
                  {
                    label: 'A > Z',
                    id: 'asc'
                  },
                  {
                    label: 'Z > A',
                    id: 'desc'
                  },
                ]
              })
              break
            default:
              break
          }
        }
        return metricArray
      },
      sortMetricDefault() {
        return `${this.productSortValue}__${this.productSortDirection}`
      },
      showPopupNotification: {
        get() {
          return this.$store.state.GeneralModule.showPopupNotification
        },
        async set(value) {
          await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
        }
      },
    },
    watch: {
      comparisonStatus(value) {
        if (!value) {
          this.durationCompareIndex = null
          this.updateState()
        }
      },
      isLoading(value) {
        if (!value) {
          this.updateState()
        }
      },
      productsResult(product) {
        this.allProductSort = []
        if (!product) {
          return
        }
        if (product[0]?.['product_name']) {
          this.allProductSort.push({
            value: 'product_name',
            label: 'Tên sản phẩm'
          })
        }
        if (product[0]?.['order_revenue']) {
          this.allProductSort.push({
            value: 'order_revenue',
            label: ALL_FIELD_FILTER['revenue'].title
          })
        }
        //todo: update ALL_FIELD_FILTER then remove this block
        if (product[0]?.['order_revenue_custom']) {
          this.allProductSort.push({
            value: 'order_revenue_custom',
            label: 'Tổng doanh số tuỳ chỉnh'
          })
        }
        if (product[0]?.['order_count_custom']) {
          this.allProductSort.push({
            value: 'order_count_custom',
            label: 'Tổng đã bán tuỳ chỉnh'
          })
        }
        // end
        for (const productKey in product[0]) {
          if (ALL_FIELD_FILTER[productKey]?.availableSort) {
            this.allProductSort.push({
              value: productKey,
              label: ALL_FIELD_FILTER[productKey].title
            })
          }
        }
        this.updateProductSort()
      }
    },
    mixins: [
      mixinStoreGetterGlobal,
      mixinStoreGetterUser,
      mixinStoreGetterPermission,
      mixinStoreGetterPayment,
      mixinStoreGetterShops,
      mixinStoreGetterProducts,
    ],
    filters: {
      formatCurrency: formatCurrency,
      formatNumberHuman: formatNumberHuman,
    },
    beforeDestroy() {
      let subPage = ['table_product_view', 'table_keyword_view', 'table_shop_view']
      if (subPage.includes(this.$route.name)) {
        return
      }
      this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.RESET_SEARCH}`)
    }
  }
</script>

<style lang="scss">

  .tab-filter-option {
    .ant-tabs-nav {
      display: flex;
      justify-content: space-between
    }

  }

</style>

<style scoped lang="scss">
  .share-report-button {
    width: 100%;
    text-align: right;
    padding: 20px 20px 0 0;

    button {
      margin-left: 12px;
    }
  }
  .market-chart {
    padding: 20px;
    border: solid 0.5px #cfd8dc66;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text-highlight {
    color: $--color-primary;;
  }

  .report-content {

    .page-title {
      margin-top: 24px;
      color: $--color-primary;
      font-size: 2rem;
      text-align: center;

      &.mobile-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
      }
    }

    .page-sub-title {
      font-size: 1.25rem;
      text-align: center;

      &.mobile-title {
        font-size: 12px;
        font-weight: normal;
        color: #484848;
      }
    }
  }

  .duration-range-comparison-wrap {
    margin: 0 16px -12px 0;
    text-align: right;

    .duration-range-comparison {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.1rem;

      &.mobile-title {
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }

      .duration-range-comparison-option-container {
        display: inline-flex;
        margin-left: 8px;
        border-radius: 6px;
        background-color: rgba(#fff, 1);
        border: rgba(gray, 0.5) 1px solid;
        overflow: hidden;

        &.mobile-view {
          border: none;
          border-radius: 0;
          background-color: unset;
          margin-left: 0;
        }

        .duration-range-comparison-option {
          padding: 4px 8px;
          border-right: gray 1px solid;
          cursor: pointer;

          &.mobile-view {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            font-size: 12px;
            margin: 0 3px;
            padding: 0 10px;
            border: #C4C4C4 1px solid;
            border-radius: 4px;

            &:last-child {
              border-right: #C4C4C4 1px solid;
            }
          }

          &:last-child {
            border-right: none;
          }

          &:hover {
            border-color: #ccc;
            box-shadow: inset 0px 1px 2px rgba(0,0,0,.2);
            color: #222;
          }

          &.active {
            background-color: rgba($--color-primary, .9);
            color: white;
          }

          &.disabled {
            opacity: 50%;

            &:hover {
              border-color: revert;
              box-shadow: none;
              color: revert;
            }
          }

        }
      }
    }
  }

  .select-option-tooltip-wrap {
    right: 0;
    top: 100%;
    z-index: 2;
  }

  .select-option-tooltip {
    padding: 16px 40px;
    background: white;
    border-radius: 8px;
    border: 1px solid;
    text-align: left;
  }

  #product-section-mobile {
    overflow-x: hidden;
  }
</style>
